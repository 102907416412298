import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import DetailsModal from "@/components/DetailsModal";
import { mapGetters } from "vuex";

export default {
  mixins: [withoutWatchersMixin],
  name: "Servis",
  components: {
    // HelloWorld,
    Multiselect,
    Pagination,
    DetailsModal,
    HasError,
  },
  data() {
    return {
      usersEquipment: [],
      exporting: {
        loading: false,
        history: false,
      },
      equipmentCategories: [],
      equipmentTypes: [],
      subcategories: [],
      users: [],
      equipment: [],
      deleteId: null,
      item: null,
      services: {},
      finishItem: null,
      selectedCategory: null,
      selectedEquipmentType: null,
      selectedEquipment: null,
      selectedSubcategory: null,
      form: new Form({
        equipment_user_id: "",
        notes: "",
      }),
      value: null,
      currentPage: 1,
      loading: false,
      deleting: false,
      modalTitle: "Dodaj servis",
      filters: {
        history: true,
        term: "",
      },
    };
  },
  methods: {
    getServices() {
      this.loading = true;
      API_URL.get("services", {
        params: {
          history: true,
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.services = response.data;
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      notifications: "notifications",
    }),
    resultsCount() {
      return this.services?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getServices();
      }, 250),
    },
    currentPage() {
      this.getServices();
    },
  },
  mounted() {
    this.getServices();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Istorija servisa",
    });
  },
};
